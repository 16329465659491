import { createSelector } from 'reselect';

const selectMain = (state: any) => state.get('dashboard');

export const selectIsLoading = createSelector(selectMain, (substate) => substate.get('isLoading'));

export const selectMainInfo = createSelector(selectMain, (substate) => substate.get('mainInfo'));

export const selectStatisticRoundValue = createSelector(selectMain, (substate) => substate.get('statisticRoundValue'));

export const selectAllDuels = createSelector(selectMain, (substate) => substate.get('allDuels'));

export const selectGames = createSelector(selectMain, (substate) => substate.get('games'));

export const selectStatusBar = createSelector(selectMain, (substate) => substate.get('statusBar'));

export const selectLastPlayerList = createSelector(selectMain, (substate) => substate.get('lastPlayerList'));

export const selectLastPlayers = createSelector(selectMain, (substate) => substate.get('lastPlayers'));

export const selectPlayGameInfo = createSelector(selectMain, (substate) => substate.get('playGameInfo'));

export const selectKeys = createSelector(selectMain, (substate) => substate.get('keys'));

export const selectSelectedGame = createSelector(selectMain, (substate) => substate.get('selectedGame'));

export const selectSelectedRate = createSelector(selectMain, (substate) => substate.get('selectedRate'));

export const selectSelectedCoin = createSelector(selectMain, (substate) => substate.get('selectedCoin'));

export const selectUserWaitingListId = createSelector(selectMain, (substate) => substate.get('userWaitingListId'));

export const selectRewardsForSignUpd = createSelector(selectMain, (substate) => substate.get('rewardsForSignUp'));

export const selectBeforeGame = createSelector(selectMain, (substate) => substate.get('beforeGame'));

export const selectProfileBack = createSelector(selectMain, (substate) => substate.get('profileBack'));

export const selectGaStatistics = createSelector(selectMain, (substate) => substate.get('gaStatistics'));

export const selectIsTelegram = createSelector(selectMain, (substate) => substate.get('isTelegram'));

export const selectWtJackpots = createSelector(selectMain, (substate) => substate.get('wtJackpots'));
